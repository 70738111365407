import { IoIosArrowDown, IoMdCheckmark, IoMdCall } from 'react-icons/io';
import StepIndicator from './StepIndicat';


const ValidationForm = ({
    currentStep,
    setCurrentStep,
    categoryFormalitie,
    titleFormalitie,
    Capitalize,
    nextStep,
    prevStep
}) => {

    console.log(categoryFormalitie, titleFormalitie);
    return (
        <div className='px-2 ps-3 pt-2'>
            <div className="flex items-center justify-start space-x-4">
                <label
                    onClick={() => prevStep()}
                    className="relative btn btn-sm btn-circle text-[#06094F] bg-white cursor-pointer hover:scale-125"
                >
                    {"<"}
                </label>
                <p className="leading-5	text-left text-lg font-bold text-first-oliver">
                    Formule d'accompagnement
                </p>
            </div>
            {categoryFormalitie && titleFormalitie && (
                <>
                    <div className='px-1'>

                        <p className="leading-5 text-left text-xs my-4 text-first-oliver">
                            {Capitalize(categoryFormalitie)} {'>'} {Capitalize(titleFormalitie)}
                        </p>
                        <p className="leading-5  my-4 justify-center">
                            Notre accompagnement prend en charge les éléments suivants :
                        </p>

                    </div>
                    <div className="bg-white rounded-lg px-1">
                        <table className="w-full text-sm text-left text-gray-500">
                            <tbody>
                                {/* Accompagnement */}
                                <tr className="bg-white font-bold">
                                    <td colSpan="3" className="px-4 py-2">Accompagnement</td>
                                </tr>
                                <tr>
                                    <td className="px-4 py-2 border-t border-b w-4/5">Accompagnement par un formaliste pour constituer votre dossier</td>
                                    {/* <td className="border-t border-b text-center text-gray-400 w-[10%]"><IoIosArrowDown className="w-7 h-7 inline" /></td> */}
                                    <td className="border-t border-b bg-sky-50 text-center text-green-400 w-[10%]">
                                        <IoMdCheckmark size={40} className="w-7 h-7 inline" />
                                        {/* <div className="w-7 h-7 bg-black rounded-full  flex items-center justify-center">
                                        </div> */}
                                    </td>
                                </tr>
                                <tr>
                                    <td className="px-4 py-2 border-t border-b w-4/5">Traitement moyen du dossier dans les 48h</td>
                                    {/* <td className="border-t border-b text-center text-gray-400 w-[10%]"><IoIosArrowDown className="w-7 h-7 inline" /></td> */}
                                    <td className="border-t border-b bg-sky-50 text-center text-green-400 w-[10%]"><IoMdCheckmark size={40} className="w-7 h-7 inline" /></td>
                                </tr>
                                <tr>
                                    <td className="px-4 py-2 border-t border-b w-4/5">Garantie anti-rejet du Greffe</td>
                                    {/* <td className="border-t border-b text-center text-gray-400 w-[10%]"><IoIosArrowDown className="w-7 h-7 inline" /></td> */}
                                    <td className="border-t border-b bg-sky-50  text-center text-green-400 w-[10%]"><IoMdCheckmark size={40} className="w-7 h-7 inline" /></td>
                                </tr>
                                <tr>
                                    <td className="px-4 py-2 border-t border-b w-4/5">Information sur les obligations légales</td>
                                    {/* <td className="border-t border-b text-center text-gray-400 w-[10%]"><IoIosArrowDown className="w-7 h-7 inline" /></td> */}
                                    <td className="border-t border-b bg-sky-50 text-center text-green-400 w-[10%]"><IoMdCheckmark size={40} className="w-7 h-7 inline" /></td>
                                </tr>

                                {categoryFormalitie === "creation" && (
                                    <>
                                        <tr>
                                            <td className="px-4 py-2 border-t border-b w-4/5">Aide au choix du régime fiscal</td>
                                            {/* <td className="border-t border-b text-center text-gray-400 w-[10%]"><IoIosArrowDown className="w-7 h-7 inline" /></td> */}
                                            <td className="border-t border-b bg-sky-50 text-center text-green-400 w-[10%]"><IoMdCheckmark size={40} className="w-7 h-7 inline" /></td>
                                        </tr>
                                    </>
                                )}

                                {/* Formalités administratives */}
                                <tr className="bg-white font-bold">
                                    <td colSpan="3" className="px-4 py-2">Formalités administratives</td>
                                </tr>
                                <tr>
                                    <td className="px-4 py-2 border-t border-b w-4/5">Validation de votre dossier par un formaliste</td>
                                    {/* <td className="border-t border-b text-center text-gray-400 w-[10%]"><IoIosArrowDown className="w-7 h-7 inline" /></td> */}
                                    <td className="border-t border-b bg-sky-50 text-center text-green-400 w-[10%]"><IoMdCheckmark size={40} className="w-7 h-7 inline" /></td>
                                </tr>
                                <tr>
                                    <td className="px-4 py-2 border-t border-b  w-4/5">Dépôt du dossier auprès du Greffe</td>
                                    {/* <td className="border-t border-b text-center text-gray-400 w-[10%]"><IoIosArrowDown className="w-7 h-7 inline" /></td> */}
                                    <td className="border-t border-b bg-sky-50 text-center text-green-400 w-[10%]"><IoMdCheckmark size={40} className="w-7 h-7 inline" /></td>
                                </tr>
                                <tr>
                                    <td className="px-4 py-2 border-t border-b  w-4/5">Inscription au registre des bénéficiaires effectifs (RBE)</td>
                                    {/* <td className="border-t border-b text-center text-gray-400  w-[10%]"><IoIosArrowDown className="w-7 h-7 inline" /></td> */}
                                    <td className="border-t border-b bg-sky-50 text-center text-green-400  w-[10%]"><IoMdCheckmark size={40} className="w-7 h-7 inline" /></td>
                                </tr>
                                <tr>
                                    <td className="px-4 py-2 border-t border-b  w-4/5">Envoi des éléments de validation (récépissé du greffe, KBIS)</td>
                                    {/* <td className="border-t border-b text-center text-gray-400  w-[10%]"><IoIosArrowDown className="w-7 h-7 inline" /></td> */}
                                    <td className="border-t border-b bg-sky-50 text-center text-green-400  w-[10%]"><IoMdCheckmark size={40} className="w-7 h-7 inline" /></td>
                                </tr>



                                {/* Dossier juridique */}
                                <tr className="bg-white font-bold">
                                    <td colSpan="3" className="px-4 py-2">Dossier juridique</td>
                                </tr>
                                <tr>
                                    <td className="px-4 py-2 border-t border-b w-4/5">Rédaction des statuts</td>
                                    {/* <td className="text-center text-gray-400 border-t border-b w-[10%]"><IoIosArrowDown className="w-7 h-7 inline" /></td> */}
                                    <td className="border-t border-b bg-sky-50 text-center w-[10%]">
                                        <div className="items-center text-red-600 text-3xl">✕</div>
                                    </td>
                                </tr>

                                <tr>
                                    <td className="px-4 py-2 border-t border-b w-4/5">Pièces obligations : statuts, pièces d'identité, pouvoir, etc.</td>
                                    {/* <td className="border-t border-b text-center text-gray-400 w-[10%]"><IoIosArrowDown className="w-7 h-7 inline" /></td> */}
                                    <td className="border-t border-b bg-sky-50 text-center text-green-400 w-[10%]"><IoMdCheckmark className="w-7 h-7 inline" /></td>
                                </tr>
                            </tbody>
                        </table>

                    </div>
                </>

            )}

            <div className="flex justify-between p-2">
                <StepIndicator currentStep={currentStep} setCurrentStep={setCurrentStep} categoryFormalitie={categoryFormalitie} />

                <button
                    className="p-2 rounded-lg px-8 bg-[#06094F] text-white hover:bg-[#3F83CA] hover:border-[#3F83CA] transition-colors duration-150"
                    onClick={nextStep}
                >
                    Continuer
                </button>
            </div>
        </div>
    );
};


export default ValidationForm;

