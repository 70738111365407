import React, { useState, useContext, useEffect } from "react";
import {
  MdClose,
  MdMenu,
  MdAdd,
  MdSettings,
  MdLogout,
  MdLock,
  MdOutlineQuestionAnswer,
  MdModeEdit,

  // MdOutlineCoffee,
} from "react-icons/md";

import { LuArrowRightFromLine } from "react-icons/lu";

import { FaUser, FaEnvelope, FaAngleLeft } from "react-icons/fa";
import { BiArrowFromLeft } from "react-icons/bi";

import { VscTriangleUp } from "react-icons/vsc";

import logo from "../assets/oliverLogo.png";
import logoInfogreffe from "../assets/logoOliver_vanillaDark3.jpg";
import logoInfogreffebeta from "../assets/logoOliver_infogreffe2_beta3.png";
import logoPlus from "../assets/oliverPlusLogo.png";
import logoPlusInfogreffe from "../assets/oliverPlusLogo_infogreffe.png";
import showSideBar from "../assets/showSideBar.svg"
import homeIcon from "../assets/homeIcon.svg"
import DarkMode from "./DarkMode";
import Modal from "./Modal";
import Setting from "./settings/Settings";
import AdminPanel from "./interface_admin/AdminPanel";
import Login from "./auth/Login";
import UserProfile from "./user_profile/UserProfile";
import Formalities from "./user_profile/Formalities"
import { AuthContext } from "../context/AuthContext";
import { useAppContext } from "../context/AppContext";
import { useColorsContext } from "../context/GlobalContext";
import { FaStar } from "react-icons/fa6";
import { FaChevronCircleLeft } from "react-icons/fa";
import Contact from "./Contact";
import Faq from "./Faq";
import Cgus from "./auth/Cgus";
import ContactExpertInformationForm from "./right_panel/ContactExpertInformationForm";
import { IoIosInformationCircle } from "react-icons/io";
import { deleteDoc, doc, getDoc, updateDoc } from "firebase/firestore";
import { db } from "../firebase.config";
import { SlOptions } from "react-icons/sl";
import { RiDeleteBinLine } from "react-icons/ri";
import ConversationsList from "./ConversationsList";
/**
 * A sidebar component that displays a list of nav items and a toggle
 * for switching between light and dark modes.
 *
 * @param {Object} props - The properties for the component.
 */
const SideBar = ({
  sideBarOpen,
  setSideBarOpen,
  buttonsOpen,
  setButtonsOpen,
  setOpenMenuId,
  openMenuId,
  isMobile,
  showPanel,
  setShowPanel,
  modalLoginOpen,
  setModalLoginOpen
}) => {
  const { subdomain } = useColorsContext();
  const [modalCgusOpen, setModalCgusOpen] = useState(false);
  const [cgus, setCgus] = useState("");
  const [modalContactExpertInformationOpen, setModalContactExpertInformationOpen] = useState(false);



  const {
    compagnyInfos,
    setCompagnyInfos,
    clearChat,
    conversations,
    currentUser,
    isAdmin,
    version,
    setMessages,
    setCurrentConversationId,
    setIsQuestionPersonnel,
    formalitieJuridique,
  } = useAppContext();

  const [modalSettingsOpen, setModalSettingsOpen] = useState(false);
  const [modalSettingsAdminOpen, setModalSettingsAdminOpen] = useState(false);
  const [modalProfileOpen, setModalProfileOpen] = useState(false);
  const [modalContactOpen, setModalContactOpen] = useState(false);
  const [modalFaqOpen, setModalFaqOpen] = useState(false);
  const [modalConfiramtionDelete, setModalConfiramtionDelete] = useState(false);

  const { handleLogout } = useContext(AuthContext);

  const [activeTab, setActiveTab] = useState('formalités');
  const [convoId, setConvoId] = useState('');
  const [editingId, setEditingId] = useState(null);
  const [newTitle, setNewTitle] = useState("");



  function formatNumberWithCommas(number) {
    var usFormat = number.toLocaleString("en-US");
    return usFormat.replaceAll(",", " ");
  }

  // function handleResize() {
  //   window.innerWidth <= 720 ? setOpen(false) : setOpen(false);
  // }

  const handleCompagnyInfos = (info, value) => {
    if (value === "")
      setCompagnyInfos((prevInfos) => ({ ...prevInfos, [info]: null }));
    else if (["effectif_moyen", "chiffre_affaires", "bilan"].includes(info)) {
      value = parseInt(value.replaceAll(" ", ""));
      if (value >= 0) {
        setCompagnyInfos((prevInfos) => ({
          ...prevInfos,
          [info]: formatNumberWithCommas(value),
        }));
      }
    } else {
      setCompagnyInfos((prevInfos) => ({ ...prevInfos, [info]: value }));
    }
  };

  const textTooltip = "Si vous fournissez des solutions (produits, services, prestations, etc.) aidant les entreprises dans leurs besoins administratifs ou juridiques, vous pouvez les référencer sur Oliver en cliquant sur le lien ci-dessous. Elles seront présentées de manière pertinente aux utilisateurs."


  // useEffect(() => {
  //   handleResize();
  // }, []);

  const handleSelectConversation = async (conversationId) => {
    try {
      const conversationRef = doc(db, "users", currentUser.uid, "conversations", conversationId);
      const conversationSnap = await getDoc(conversationRef);

      if (conversationSnap.exists()) {
        const conversationData = conversationSnap.data();
        setIsQuestionPersonnel(true);
        setCurrentConversationId(conversationId);
        setMessages(conversationData.messages);  // Met à jour l'état des messages avec les messages de la conversation sélectionnée
      } else {
        console.log("No conversation found!");
        setMessages([]);  // Réinitialise les messages si la conversation n'existe pas
      }
    } catch (error) {
      console.error("Error fetching conversation:", error);
      setMessages([]);  // Gère les erreurs en réinitialisant les messages
    }
  }



  const toggleMenu = (convo) => {
    if (openMenuId === convo.id) {
      setOpenMenuId(null);
    } else {
      setOpenMenuId(convo.id);
    }
  };

  const promptRename = (convoId, currentTitle) => {
    setEditingId(convoId);
    setNewTitle(currentTitle); // Initialiser avec le titre actuel
  };

  const saveNewTitle = async (convoId) => {
    if (!newTitle.trim()) return; // Ne rien faire si le titre est vide
    const conversationRef = doc(db, "users", currentUser.uid, "conversations", convoId);
    try {
      await updateDoc(conversationRef, {
        titre: newTitle.trim()
      });
      console.log("Conversation title updated.");
      setEditingId(null); // Quitter le mode édition
    } catch (error) {
      console.error("Failed to update conversation title:", error);
    }
  };


  const deleteConversation = async (conversationId) => {
    const conversationRef = doc(db, "users", currentUser.uid, "conversations", conversationId);
    try {
      await deleteDoc(conversationRef);
      console.log("Conversation deleted.");
      setModalConfiramtionDelete(false)
    } catch (error) {
      console.error("Error deleting conversation:", error);
    }
  };




  if (!sideBarOpen) {
    return ""
  }

  return (
    <div className="h-full flex flex-col justify-center">
      <div
        className={` ${sideBarOpen ? "flex-shrink-1 overflow-x-hidden bg-[#8EBAEA] min-w-[320px]" : ""} 
        flex flex-col h-full rounded-e-2xl p-3 pt-4 relative duration-300 border-slate-600;
        `}
      >
        <div className="w-full h-full flex flex-col">
          <div className={`sidebar__btn-close md:block hidden`}>
            {sideBarOpen ? (
              <>
                <div className="flex w-full justify-between">
                  <div className="">


                    <div className="group relative flex items-center ">
                      <div className="hover:bg-blue-100 w-12 h-11 rounded-lg" onClick={() => { setSideBarOpen(!sideBarOpen); }}>
                        <img src={showSideBar} alt="Icon" />
                      </div>
                      <span className="absolute bottom-full -mb-[5rem] -right-8 max-w-[calc(100vw-3rem)] w-14 transform scale-0 rounded bg-white p-1 text-xs text-[#06094F] group-hover:scale-100 transition duration-150 ease-in-out">
                        Rétracter
                      </span>
                    </div>
                  </div>
                  <div className="">
                    <div className="group relative flex items-center self-end">
                      <div className="hover:bg-blue-100 w-12 h-11 rounded-lg" onClick={clearChat} >
                        <img src={homeIcon} alt="Icon" className="w-12 h-11" />
                      </div>
                      <span className="absolute bottom-full -mb-[5rem] -right-2 max-w-[calc(100vw-3rem)] w-14 transform scale-0 rounded bg-white p-1 text-xs text-[#06094F] group-hover:scale-100 transition duration-150 ease-in-out">
                        Accueil
                      </span>
                    </div>
                  </div>

                </div>
              </>

            ) : (
              null
            )}
          </div>

          <div className={`md:hidden ${sideBarOpen && "rotate-180 absolute right-0 mr-4"}`} onClick={() => setSideBarOpen(!sideBarOpen)}>
            {sideBarOpen ?
              <BiArrowFromLeft className="w-8 h-8 text-[#06094F]" />
              :
              <BiArrowFromLeft className="w-8 h-8 text-[#06094F]" />

            }
          </div>

          <>
            {console.log("conversations: ", conversations)}
            <div className="mt-2 overflow-y-auto flex-grow">
              {conversations.length >= 1 && (
                <>
                  <h1 className="text-[#06094F] ps-2 py-2 font-semibold"> Historique des questions </h1>
                  <ConversationsList
                    conversations={conversations}
                    handleSelectConversation={handleSelectConversation}
                    editingId={editingId}
                    newTitle={newTitle}
                    setNewTitle={setNewTitle}
                    saveNewTitle={saveNewTitle}
                    promptRename={promptRename}
                    setModalConfiramtionDelete={setModalConfiramtionDelete}
                    setConvoId={setConvoId}
                  />
                </>
              )}
            </div>

          </>

          {
            sideBarOpen && (
              <>
                <div
                  className={
                    "nav__bottom border-[#06094F] border-t-[1px] relative w-full " +
                    (buttonsOpen || !sideBarOpen ? "flex-col" : "") +
                    " "
                  }
                >
                  <div
                    className="w-full flex justify-center absolute top-[-8px]"
                    onClick={() => {
                      setButtonsOpen(!buttonsOpen);
                    }}
                  >
                    <div
                      className={
                        "w-12 h-4 bg-[#06094F] flex items-center justify-center cursor-pointer " +
                        (!sideBarOpen ? "hidden" : "")
                      }
                    >
                      <VscTriangleUp color="#fff"
                        className={`text-neutral-600 ${buttonsOpen ? "rotate-180" : ""}`}
                      />
                    </div>
                  </div>
                  {/* <DarkMode open={open && buttonsOpen} /> */}
                  <div className={`nav py-3`}>
                    <span
                      htmlFor="setting-modal"
                      className={`justify-center py-3 flex items-center gap-x-4 w-full rounded-md cursor-pointer hover:bg-[#54E1BB] text-[#06094F] text-base
                     ${!buttonsOpen ? "" : ""} bg-[#7FF9D8] `}
                    >
                      <div className="nav__icons">
                        <FaStar fill="#06094F" />
                      </div>
                      {!(!sideBarOpen || !buttonsOpen) &&
                        (
                          <h1 className={`text-[#06094F]`}
                            onClick={() => { setModalContactExpertInformationOpen(true) }}>
                            Me référencer en tant qu'expert
                          </h1>
                        )
                      }
                    </span>
                  </div>
                  <div onClick={() => setModalContactOpen(!modalContactOpen)} className="nav">
                    <span
                      htmlFor="setting-modal"
                      className={`nav__item ${!buttonsOpen ? "justify-center" : ""}`}
                    >
                      <div className="nav__icons">
                        <FaEnvelope fill="#06094F" />
                      </div>
                      <h1 className={`${(!sideBarOpen || !buttonsOpen) && "hidden"}`}>
                        Contact
                      </h1>
                    </span>
                  </div>
                  <div onClick={() => setModalFaqOpen(!modalFaqOpen)} className="nav">
                    <span
                      htmlFor="setting-modal"
                      className={`nav__item ${!buttonsOpen ? "justify-center" : ""}`}
                    >
                      <div className="nav__icons">
                        <MdOutlineQuestionAnswer fill="#06094F" />
                      </div>
                      <h1 className={`${(!sideBarOpen || !buttonsOpen) && "hidden"}`}>FAQ</h1>
                    </span>
                  </div>

                  {isAdmin ? (
                    <>

                      <div onClick={() => setModalSettingsAdminOpen(true)} className="nav">
                        <span
                          htmlFor="setting-modal"
                          className={`nav__item ${!buttonsOpen ? "justify-center" : ""}`}
                        >
                          <div className="nav__icons">
                            <MdLock fill="#06094F" />
                          </div>
                          <h1 className={`${(!sideBarOpen || !buttonsOpen) && "hidden"}`}>
                            Paramètres Administrateur
                          </h1>
                        </span>
                      </div>
                      <div onClick={() => setModalSettingsOpen(true)} className="nav">
                        <span
                          htmlFor="setting-modal"
                          className={`nav__item ${!buttonsOpen ? "justify-center" : ""}`}
                        >
                          <div className="nav__icons">
                            <MdSettings fill="#06094F" />
                          </div>
                          <h1 className={`${(!sideBarOpen || !buttonsOpen) && "hidden"}`}>
                            Paramètres du chatbot
                          </h1>
                        </span>
                      </div>
                    </>
                  ) : null}

                  {currentUser ? (
                    <div onClick={() => setModalProfileOpen(true)} className="nav">
                      <span
                        htmlFor="setting-modal"
                        className={`nav__item ${!buttonsOpen ? "justify-center" : ""}`}
                      >
                        <div className="nav__icons">
                          <FaUser fill="#06094F" />
                        </div>
                        <h1 className={`${(!sideBarOpen || !buttonsOpen) && "hidden"}`}>
                          Mon compte
                          <p className="text-xs italic">{currentUser.email || ''}</p>
                        </h1>

                      </span>
                    </div>
                  ) : null}

                  {currentUser ? (
                    <div className="nav">
                      <div
                        onClick={handleLogout}
                        className={`nav__item ${!buttonsOpen ? "justify-center" : ""}`}
                      >
                        <div className="nav__icons">
                          <MdLogout fill="#06094F" />
                        </div>
                        <h1 className={`${(!sideBarOpen || !buttonsOpen) && "hidden"}`}>
                          Se déconnecter
                        </h1>
                      </div>
                    </div>
                  ) : (
                    <div onClick={() => setModalLoginOpen(true)} className="nav">
                      <span
                        htmlFor="setting-modal"
                        className={`nav__item ${!buttonsOpen ? "justify-center" : ""}`}
                      >
                        <div className="nav__icons">
                          <MdLock fill="#06094F" />
                        </div>
                        <h1 className={`${(!sideBarOpen || !buttonsOpen) && "hidden"}`}>
                          Se connecter
                        </h1>
                      </span>
                    </div>
                  )}


                </div >

                {sideBarOpen && buttonsOpen && (<div className="flex w-full p-3 cursor-pointer">
                  <FaAngleLeft className="ml-auto bg-white hover:scale-125 rounded-full" size={30} onClick={() => setSideBarOpen(!sideBarOpen)} color="#06094F" />
                </div>)}
              </>
            )
          }
        </div >

        <Modal
          title="Paramètres"
          modalOpen={modalSettingsOpen}
          setModalOpen={setModalSettingsOpen}
          modalSize={"medium"}
        >

          <Setting
            setModalOpen={setModalSettingsOpen}
          />
        </Modal>

        <Modal
          title=""
          modalOpen={modalConfiramtionDelete}
          setModalOpen={setModalConfiramtionDelete}
          modalSize={"auto"}
        >
          <div className="flex flex-col items-center justify-center p-4">
            {/* <img src={`${subDomains.includes(subdomain) ? logoMesFormalitesClair : logoInfogreffe}`} alt="Logo" className="w-40 mb-6" /> */}

            <p className="text-center mb-6"> Etes-vous sûr de vouloir supprimer cette  conversation ?</p>
            <div className='flex justify-between gap-4'>

              <button
                onClick={() => {
                  setModalConfiramtionDelete(false)
                }}
                className="signupFormContainerContinuer text-white  font-bold"
              >
                Annuler
              </button>
              <button
                onClick={() => {
                  deleteConversation(convoId)
                }}
                className="signupFormContainerContinuer bg-red-500 text-white font-bold"
              >
                Supprimer
              </button>
            </div>
          </div>
        </Modal>

        <Modal
          title={cgus}
          modalOpen={modalCgusOpen}
          setModalOpen={setModalCgusOpen}
          modalSize={"cgus"}
        >
          <Cgus setCgus={setCgus} content={cgus} />
        </Modal>
        {
          isAdmin && (
            <Modal
              title="Paramètres Administrateur"
              modalOpen={modalSettingsAdminOpen}
              setModalOpen={setModalSettingsAdminOpen}
              modalSize={"large"}
            >
              <AdminPanel />
            </Modal>
          )
        }

        <Modal
          title={"Mon compte"}
          modalOpen={modalProfileOpen}
          setModalOpen={setModalProfileOpen}
          modalSize={"large"}
          setActiveTab={setActiveTab}
          activeTab={activeTab}
        // titleIcon={<FaUser className="mr-2" />}
        >


          {activeTab === 'requêtes' ? (
            <UserProfile setOpen={setModalProfileOpen} />
          ) : (
            <Formalities setModalProfileOpen={setModalProfileOpen} formalitieJuridique={formalitieJuridique} />
          )}
        </Modal>

        <Modal
          title={"Contact"}
          modalOpen={modalContactOpen}
          setModalOpen={setModalContactOpen}
          modalSize={"auto"}
          titleIcon={<FaEnvelope className="mr-2 text-[1.3em]" />}
        >
          <Contact setOpen={setModalContactOpen} />
        </Modal>

        <Modal
          title={"FAQ"}
          modalOpen={modalFaqOpen}
          setModalOpen={setModalFaqOpen}
          modalSize={"auto"}
          titleIcon={<MdOutlineQuestionAnswer className="mr-2 text-[1.3em]" />}
        >
          <Faq />
        </Modal>
      </div >
      <div className={`${(!sideBarOpen) ? "hidden w-full mt-auto justify-around items-center text-[#06094F] z-10" : "flex w-full mt-auto justify-around items-center text-[#06094F] z-10"} ${isMobile && "bg-[#8EBAEA] rounded-se-2xl"} space-x-2`}>

        <div className="text-center">
          <a onClick={() => { setModalCgusOpen(true); setCgus("Mentions légales"); }} className="cursor-pointer text-xs hover:border-b-2 hover:border-[#06094F] whitespace-nowrap overflow-hidden text-ellipsis" style={{ fontSize: '0.65rem' }}>
            Mentions légales
          </a>
        </div>
        <div className="text-center">
          <a onClick={() => { setModalCgusOpen(true); setCgus("CGU"); }} className="cursor-pointer text-xs hover:border-b-2 hover:border-[#06094F] whitespace-nowrap overflow-hidden text-ellipsis" style={{ fontSize: '0.65rem' }}>
            CGVU
          </a>
        </div>
        <div className="text-center">
          <a onClick={() => { setModalCgusOpen(true); setCgus("Politique de confidentialité"); }} className="cursor-pointer text-xs hover:border-b-2 hover:border-[#06094F] whitespace-nowrap overflow-hidden text-ellipsis" style={{ fontSize: '0.65rem' }}>
            Politique de confidentialité
          </a>
        </div>
      </div>

      <ContactExpertInformationForm modalContactExpertInformationOpen={modalContactExpertInformationOpen} setModalContactExpertInformationOpen={setModalContactExpertInformationOpen} />

    </div>
  );
};

export default SideBar;
