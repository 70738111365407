import React, { useState } from 'react';
import { FaRegEdit, FaCheck } from 'react-icons/fa';
import { MdOutlineCancel } from "react-icons/md";

const EditableRoweDevis = ({ row, updateRow, isEditing, setEditing, formality, deleteRow, eDevisIsSand }) => {
    const [editableRow, setEditableRow] = useState(row);

    const handleChange = (e) => {
        const { name, value } = e.target;
        const updatedRow = { ...editableRow, [name]: value };
        setEditableRow(updatedRow);
    };

    const handleSave = () => {
        updateRow(row.id, editableRow);
        setEditing(row.id, false);
    };

    const handleEdit = () => {
        setEditing(row.id, true);
    };

    console.log("isEditing :", isEditing);

    return (
        <tr>
            <td className='px-1 border-s border-r border-b border-black'>
                {isEditing ? (
                    <input type="text" value={editableRow.description} onChange={handleChange} name="description" className="border px-2 py-1" style={{ width: '100%' }} />
                ) : (
                    <span>{editableRow.description}</span>
                )}
            </td>
            {!eDevisIsSand && (
                <>
                    <td className='px-1 border-r border-b text-right border-black'>
                        {isEditing ? (
                            <input type="number" value={editableRow.qty} onChange={handleChange} name="qty" className="border px-2 py-1" style={{ width: '100%' }} />
                        ) : (
                            <span>{!isNaN(parseFloat(editableRow.qty)) ? parseFloat(editableRow.qty).toFixed(0) : 'Valeur invalide'}</span>

                        )}
                    </td>
                    <td className='px-1 border-r border-b text-right border-black'>
                        {isEditing ? (
                            <div className='flex justify-between gap-2'>
                                <style jsx>{`
        /* Chrome, Safari, Edge, Opera */
        input[type='number']::-webkit-outer-spin-button,
        input[type='number']::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
        
        /* Firefox */
        input[type='number'] {
          -moz-appearance: textfield;
        }
      `}</style>
                                <input
                                    type="number"
                                    value={editableRow.unitPrice}
                                    onChange={handleChange}
                                    name="unitPrice"
                                    className="border px-2 py-1"
                                    style={{ width: '100%' }}
                                />
                                <span className='my-auto'> €</span>
                            </div>
                        ) : (
                            <span className='text-right'>{editableRow.unitPrice} €</span>
                        )}
                    </td>
                    <td className='px-1 border-r border-b text-right border-black'>
                        {isEditing ? (
                            <div className='flex justify-between gap-2'>
                                <input type="number" value={editableRow.tax} onChange={handleChange} name="tax" className="border px-2 py-1" style={{ width: '100%' }} />
                                <span className='my-auto'> %</span>
                            </div>
                        ) : (
                            <span>{editableRow.tax} %</span>

                        )}
                    </td>
                </>
            )}
            <td className="border-r  border-b text-right border-black px-1 py-1">
                <span>{(editableRow.qty * editableRow.unitPrice).toFixed(2)} €</span>
            </td>
            {formality.status === "En attente du devis" &&
                <>
                    <td className=" border-black px-1 py-1 text-center">
                        <div className='flex justify-between gap-1'>

                            {isEditing ? (
                                <>
                                    <FaCheck
                                        className='p-1 cursor-pointer rounded-md border hover:bg-gray-200'
                                        size={30}
                                        color='#0cde64'
                                        onClick={handleSave}
                                    />
                                </>
                            ) : (
                                <FaRegEdit
                                    className='p-1 cursor-pointer rounded-md border hover:bg-gray-200'
                                    size={30}
                                    onClick={handleEdit}
                                />
                            )}
                            {/* </td>

<td className="border-black px-1 py-1 text-center"> */}
                            <MdOutlineCancel
                                className='p-1 cursor-pointer rounded-md border hover:bg-gray-200'
                                size={30}
                                color='#f00'
                                onClick={() => { deleteRow(row.id) }}
                            />
                        </div>
                    </td>
                </>
            }
        </tr>
    );
};

export default EditableRoweDevis;
