import React, { useContext, useEffect, useState } from 'react';
import { Dropdown, DropdownHeader, DropdownItem, DropdownDivider } from "flowbite-react";
import { PiSignOutFill } from "react-icons/pi";
import { FaUser } from 'react-icons/fa6';
import { AuthContext } from '../../context/AuthContext';
import { useLocation, useNavigate } from 'react-router-dom';
import { ClipboardList } from 'lucide-react';
import { useAppContext } from '../../context/AppContext';
import Modal from '../Modal';
import UserProfile from '../user_profile/UserProfile';
import Formalities from '../user_profile/Formalities';

// Composant Avatar personnalisé
const CustomAvatar = ({ initial }) => {
    return (
        <div className="flex items-center justify-center w-10 h-10 rounded-full bg-[#06094F] text-white">
            <span className="text-xl">{initial}</span>
        </div>
    );
};

const UserDropdownMenu = ({ currentUser }) => {
    const { handleLogout } = useContext(AuthContext);
    const { isAdmin, isformaliste, formalitieJuridique } = useAppContext();
    const [modalProfileOpen, setModalProfileOpen] = useState(false);
    const [activeTab, setActiveTab] = useState('formalités');
    const location = useLocation();
    console.log(currentUser)

    const navigate = useNavigate();

    // Vérifier les paramètres d'URL au chargement
    useEffect(() => {
        // Créer un objet URLSearchParams pour faciliter la lecture des paramètres
        const searchParams = new URLSearchParams(location.search);
        const moncompte = searchParams.get('moncompte');

        // Si moncompte=true, ouvrir le modal
        if (moncompte === 'true') {
            setModalProfileOpen(true);
            // Optionnel : Nettoyer l'URL après ouverture du modal
            navigate(location.pathname, { replace: true });
        }
    }, [location]);

    return (
        <>
            <Dropdown
                label={<CustomAvatar initial={currentUser.displayName[0].toUpperCase()} />}
                arrowIcon={false}
                inline
            >
                <DropdownHeader onClick={() => setModalProfileOpen(true)} className='hover:bg-gray-100'>
                    <div className='flex justify-between gap-2 cursor-pointer'>
                        <div className='flex items-center justify-center'>
                            <FaUser size={25} />
                        </div>
                        <div>
                            <span className="block text-sm">{currentUser.displayName}</span>
                            <span className="block truncate text-sm font-medium">{currentUser.email}</span>
                        </div>
                    </div>
                </DropdownHeader>
                <DropdownDivider />
                <DropdownItem
                    icon={() => <ClipboardList size={25} className="mr-2" />}
                    onClick={() => {
                        if (isformaliste || isAdmin) {
                            navigate('/registre-formalites');
                        }
                    }}
                >
                    Registre des formalites
                </DropdownItem>
                <DropdownItem
                    icon={() => {
                        return <PiSignOutFill size={25} className="mr-2" />
                    }}
                    onClick={handleLogout}
                >
                    Se déconnecter
                </DropdownItem>
            </Dropdown>

            <Modal
                title={"Mon compte"}
                modalOpen={modalProfileOpen}
                setModalOpen={setModalProfileOpen}
                modalSize={"large"}
                setActiveTab={setActiveTab}
                activeTab={activeTab}
            // titleIcon={<FaUser className="mr-2" />}
            >


                {activeTab === 'requêtes' ? (
                    <UserProfile setOpen={setModalProfileOpen} />
                ) : (
                    <Formalities setModalProfileOpen={setModalProfileOpen} formalitieJuridique={formalitieJuridique} />
                )}
            </Modal>
        </>
    );
};

export default UserDropdownMenu;