import React, { useState, useEffect } from 'react';
import { IoMdLock } from 'react-icons/io';
import { CardElement, Elements, useStripe, useElements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import CheckoutForm from './CheckoutForm';
import { fetchData } from "../../../../utils/ask_api";
import { GooglePayButton } from '@google-pay/button-react';
import { FaCcVisa, FaCcMastercard, FaCcAmex, FaGooglePay, FaPaypal } from 'react-icons/fa';
import PaymentOptions from "./PaymentOptions"
import AddressAutocomplete from './AddressAutocomplete';
console.log("process.env.REACT_APP_STRIPE_PUBLIC_KEY:", process.env.REACT_APP_STRIPE_PUBLIC_KEY)
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        // Met à jour l'état pour que le prochain rendu affiche l'interface de repli.
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        // Tu peux également enregistrer l'erreur quelque part.
        console.log(error, errorInfo);
    }

    render() {
        if (this.state.hasError) {
            // Tu peux rendre n'importe quelle interface de repli.
            return <h1>Quelque chose a mal tourné.</h1>;
        }

        return this.props.children;
    }
}

const PaymentForm = ({
    currentStep,
    setCurrentStep,
    categoryFormalitie,
    titleFormalitie,
    setAcceptTerms,
    setAcceptCgus,
    Capitalize,
    setModalTitle,
    setModalCguOpen,
    acceptCgus,
    acceptTerms,
    handleSubmitFormalitie,
    handleInputChange,
    billingInformation,
    validations,
    piecesRequises,
    eDevisIsSand,
    prevStep,
    setShowPaymentForm

}) => {
    const [paymentMethod, setPaymentMethod] = useState('card');
    const [clientSecret, setClientSecret] = useState("");
    const [messageError, setMessageError] = useState(null);



    useEffect(() => {
        const fetchDataFromApi = async () => {
            try {
                const formalitieData = { amount: billingInformation.totalAmount, currency: "eur" };
                console.log("fetch : create-payment-intent ", formalitieData);
                const data = await fetchData("create-payment-intent", formalitieData, "POST")
                console.log("clientSecret: ", data);
                console.log("clientSecret: ", data.clientSecret);
                setClientSecret(data.clientSecret)
            } catch (error) {
                console.error("Error fetching data: ", error);
            }

        }
        fetchDataFromApi();

    }, []);

    const appearance = {
        theme: 'stripe',
    };
    const options = {
        clientSecret,
        appearance,
    };
    console.log("PaymentForm", currentStep, Capitalize, categoryFormalitie, titleFormalitie)
    if (!clientSecret) {
        return <p>Chargement...</p>;
    }

    return (
        <div className='loginContainerContent ps-2 pt-2'>
            <div className="flex items-center justify-start space-x-4">

                <label
                    onClick={() => {
                        if (eDevisIsSand) {
                            setShowPaymentForm(false);
                        } else {
                            prevStep()
                        }
                    }
                    }
                    className="relative btn btn-sm btn-circle text-[#06094F] bg-white cursor-pointer hover:scale-125"
                >
                    {"<"}
                </label>

                <p className="leading-5	text-left text-lg font-bold text-first-oliver">
                    Paiement de la commande
                </p>
            </div>

            {categoryFormalitie && titleFormalitie && (
                <div className='px-1'>

                    <p className="leading-5 text-left text-xs my-4 text-first-oliver">
                        {Capitalize(categoryFormalitie)} {'>'} {Capitalize(titleFormalitie)} {'>'} Commande de <span className='font-bold'>{billingInformation.totalAmount.toFixed(2)} € TTC</span>
                    </p>

                    <div className="w-full text-left mb-2">
                        <h2 className="font-bold">Informations de facturation</h2>
                    </div>

                    <div className='flex flex-wrap items-stretch w-full'>

                        <input
                            type="text"
                            name="prenom"
                            id="prenom-f"
                            placeholder="Prénon"
                            className="my-1  border border-[#d1d5db] rounded-lg w-full input bg-white flex-1 min-w-0 mr-2 mb-2 sm:mb-0"
                            value={billingInformation.prenom}
                            onChange={handleInputChange}
                            required
                            style={{ borderColor: validations.isFirstNameValid ? "" : "red" }}
                        />
                        <input
                            type="text"
                            name="nom"
                            id="nom-f"
                            placeholder="Nom"
                            className="my-1  border border-[#d1d5db] rounded-lg w-full input bg-white flex-1 min-w-0"
                            value={billingInformation.nom}
                            onChange={handleInputChange}
                            required
                            style={{ borderColor: validations.isLastNameValid ? "" : "red" }}
                        />
                    </div>

                    <input
                        type="text"
                        name="email"
                        id="email-f"
                        placeholder="Email"
                        className="my-1  border border-[#d1d5db] rounded-lg w-full input bg-white"
                        value={billingInformation.email}
                        onChange={handleInputChange}
                        required
                        style={{ borderColor: validations.isEmailValid ? "" : "red" }}
                    />
                    <input
                        type="text"
                        name="companyName"
                        id="companyName-f"
                        placeholder="Raison Sociale (facultatif)"
                        className="my-1  border border-[#d1d5db] rounded-lg w-full input bg-white"
                        value={billingInformation.companyName}
                        onChange={handleInputChange}
                        required
                        style={{ borderColor: validations.isCompanyNameValid ? "" : "red" }}
                    />
                    <input
                        type="tel"
                        name="tel"
                        id="tel-f"
                        placeholder="Télephone"
                        className="my-1  border border-[#d1d5db] rounded-lg w-full input bg-white"
                        value={billingInformation.tel}
                        onChange={handleInputChange}
                        required
                        style={{ borderColor: validations.isTelValid ? "" : "red" }}
                    />

                    <AddressAutocomplete onAddressSelect={(suggestion) => console.log(suggestion)} handleInputChangeAll={handleInputChange} />
                    {messageError && <div id="payment-message-error" className='text-red-500'>{messageError}</div>}

                    <div className="w-full text-left my-6">
                        <h2 className="font-bold text-left">Paiement</h2>
                    </div>

                    <div className="flex justify-between items-center">
                        <IoMdLock className="w-6 h-6 text-black" />
                        <h1 className="font-bold">SSL Secure Connection</h1>
                    </div>
                    {/* <PaymentOptions setPaymentMethod={setPaymentMethod} /> */}
                    <div >
                        <div className="mt-4">
                            {paymentMethod === 'card' && (
                                <ErrorBoundary>
                                    {clientSecret && (
                                        <Elements options={options} stripe={stripePromise}>
                                            <CheckoutForm
                                                currentStep={currentStep}
                                                acceptCgus={acceptCgus}
                                                acceptTerms={acceptTerms}
                                                setCurrentStep={setCurrentStep}
                                                setAcceptTerms={setAcceptTerms}
                                                setAcceptCgus={setAcceptCgus}
                                                setModalTitle={setModalTitle}
                                                setModalCguOpen={setModalCguOpen}
                                                handleSubmitFormalitie={handleSubmitFormalitie}
                                                piecesRequises={piecesRequises}
                                                billingInformation={billingInformation}
                                                validations={validations}
                                                eDevisIsSand={eDevisIsSand}
                                                categoryFormalitie={categoryFormalitie}
                                                setMessageError={setMessageError}

                                            />
                                        </Elements>
                                    )}
                                </ErrorBoundary>
                            )}
                        </div>
                    </div>
                </div>
            )}
        </div>)
}

export default PaymentForm;
