import { IoMdCheckmark } from 'react-icons/io';
import { db } from "../../../../firebase.config";
import { doc, getDoc } from "firebase/firestore";
import StepIndicator from './StepIndicat';

const ConfirmationOrder = ({
    currentStep,
    setCurrentStep,
    categoryFormalitie,
    titleFormalitie,
    Capitalize,
    nextStep,
    packGlobal,
    prixHT,
    totalHT,
    location,
    fraisAll,
    montantTVA,
    totalTTC,
    department,
    prevStep
}) => {

    const getPrixHT = (prix, department) => {
        if (typeof prix === 'object') {
            return department === "metropole" ? prix.fraisAnnonceLegaleCreation_FRmetro : prix.fraisAnnonceLegaleCreation_ReuMay;
        }
        return prix || 0;
    };

    const feesKeys = Object.keys(fraisAll);
    const honoraires = feesKeys.filter(key => key.toLowerCase().startsWith('honoraires'));
    const fraisObligatoires = feesKeys.filter(key => !key.toLowerCase().startsWith('honoraires'));



    console.log(categoryFormalitie, titleFormalitie);
    console.log("ConfirmationOrder", Capitalize)
    return (
        <div className='px-2 ps-3 pt-2'>
            <div className="flex items-center justify-start space-x-4">
                <label
                    onClick={() => prevStep()}
                    className="relative btn btn-sm btn-circle text-[#06094F] bg-white cursor-pointer hover:scale-125"
                >
                    {"<"}
                </label>
                <span className="leading-5 text-left text-lg font-bold text-first-oliver">
                    Validation de la commande
                </span>
            </div>

            {categoryFormalitie && titleFormalitie && (
                <div className=''>
                    <p className="leading-5 text-left text-xs my-4 text-first-oliver">
                        {Capitalize(categoryFormalitie)} {'>'} {Capitalize(titleFormalitie)}
                    </p>
                    <p className="leading-5  my-4 justify-start">
                        Veuillez vérifier les informations de la commande avant de payer.
                    </p>
                    <div className="max-w-4xl mx-auto">
                        <div className="overflow-x-auto bg-white p-4 rounded-lg mb-1">
                            <table className="w-full text-sm text-left text-gray-700">
                                <tbody>
                                    {/* Dossier juridique */}
                                    <tr className="bg-white">
                                        <td className="ps-2 pt-2 text-base font-bold w-full">
                                            <strong> Formalités de {Capitalize(categoryFormalitie)} {'>'} {Capitalize(titleFormalitie)} </strong>
                                        </td>
                                        <td className="w-60"></td>
                                        <td className='text-end pr-2 w-full text-base font-bold whitespace-nowrap'>Prix</td>
                                    </tr>
                                    <tr>
                                        <td className="ps-2 pt-1 w-full">
                                            <div className="flex flex-col">
                                                <div className="flex flex-col">
                                                    <p className='text-base font-bold text-first-oliver-color'>
                                                        Honoraires
                                                    </p>
                                                    {/* <p className="text-sm">
                                                        {fraisAll.honoraires.description}
                                                    </p> */}
                                                </div>
                                            </div>
                                        </td>
                                        {/* <td className="w-60"></td>
                                        <td className="text-right  pr-2 w-full whitespace-nowrap">
                                            <div>{packGlobal ? `${prixHT}` : `${fraisAll.honoraires.prix}`} € </div>
                                        </td> */}
                                    </tr>
                                    {honoraires.map(key => (
                                        <tr key={key}>
                                            <td className="ps-2 pt-1 w-full">
                                                {/* <div className="flex flex-col"> */}
                                                {/* <p className='text-base font-bold'> */}
                                                {fraisAll[key].description}
                                                {/* </p> */}
                                                {/* </div> */}
                                            </td>
                                            <td className="w-60"></td>
                                            <td className="text-right pr-2 w-full whitespace-nowrap">
                                                <div>{packGlobal ? `${prixHT}` : `${fraisAll[key].prix}`} €</div>
                                            </td>
                                        </tr>
                                    ))}
                                    <tr>
                                        <td className="ps-2 pt-2 w-full">  <p className='font-bold text-base text-first-oliver-color mr-8'>Frais administratifs obligatoires</p>
                                        </td>
                                        <td className="w-60"></td>
                                        <td className="text-end  pr-2 font-bold w-full whitespace-nowrap">
                                        </td>
                                    </tr>
                                    {fraisObligatoires.map(key => (
                                        fraisAll[key].prix && fraisAll[key].prix !== 0 &&
                                        <tr key={key}>
                                            <td className="px-2 w-full"> {fraisAll[key].description || `Autres frais`}
                                            </td>
                                            <td className="w-60"></td>
                                            <td className="text-end pr-2 w-full whitespace-nowrap">
                                                {packGlobal ? "" : `${getPrixHT(fraisAll[key].prix, department)} €`}
                                            </td>
                                        </tr>
                                    ))}
                                    {/* {fraisAll?.fraisRBE?.prix && fraisAll?.fraisRBE?.prix !== 0 &&
                                        <tr>
                                            <td className="ps-2 pt-2 w-full">  <p className='font-bold mr-8'>Coûts liés au Registre des Bénéficiaires Effectifs (RBE)</p>
                                            </td>
                                            <td className="w-60"></td>
                                            <td className="text-end  pr-2 font-bold w-full whitespace-nowrap">
                                                {packGlobal ? "" : `${fraisAll.fraisRBE.prix} €`}
                                            </td>
                                        </tr>
                                    }
                                    {fraisAll?.annonceLegaleCreationSC?.prix && fraisAll?.annonceLegaleCreationSC?.prix !== 0 &&
                                        <tr>
                                            <td className="px-2 w-full"> {fraisAll.annonceLegaleCreationSC.description || "Frais de publication légale"}
                                            </td>
                                            <td className="w-60"></td>
                                            <td className="text-end  pr-2 w-full whitespace-nowrap">
                                                {packGlobal ? "" : `${getPrixHT(fraisAll.annonceLegaleCreationSC.prix, location.isFranceMetropolitan)} €`}
                                            </td>
                                        </tr>
                                    }
                                    {fraisAll?.fraisGreffeConstitution?.prix && fraisAll?.fraisGreffeConstitution?.prix !== 0 &&
                                        <tr>
                                            <td className="px-2 w-full"> {fraisAll.fraisGreffeConstitution.description || "Frais de Greffe pour la constitution"}.
                                            </td>
                                            <td className="w-60"></td>
                                            <td className="text-end  pr-2 w-full whitespace-nowrap">
                                                {packGlobal ? "" : `${fraisAll.fraisGreffeConstitution.prix} €`}
                                            </td>
                                        </tr>
                                    }
                                    {fraisAll?.fraisGreffeDepot?.prix && fraisAll?.fraisGreffeDepot?.prix !== 0 &&
                                        <tr>
                                            <td className="px-2 2-full"> {fraisAll.fraisGreffeDepot.description || "Frais de dépôt auprès du greffe"}.
                                            </td>
                                            <td className="w-60"></td>
                                            <td className="text-end  pr-2 w-full whitespace-nowrap">
                                                {packGlobal ? "" : `${fraisAll.fraisGreffeDepot.prix} €`}
                                            </td>
                                        </tr>
                                    } */}

                                </tbody>
                            </table>
                        </div>

                        <div className="overflow-x-auto bg-white p-4 rounded-lg shadow-2xl">
                            <table className="w-full text-sm text-left text-gray-700">
                                <tbody>
                                    <tr>
                                        <td className="ps-2 pt-2 mr-15">
                                            <div className='flex justify-between items-center'>
                                                <div>
                                                    <p className='text-base font-bold'>Récapitulatif</p>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="px-2 pt-2 w-full">  <p className='text-base font-bold mr-10'>Total commande HT</p>
                                        </td>
                                        <td className="w-60"></td>
                                        <td className="text-end font-bold  pr-2 w-full whitespace-nowrap">
                                            {packGlobal ? `${prixHT} €` : `${(totalHT.toFixed(2))} €`}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="px-2 text-base w-full"> Total TVA
                                        </td>
                                        <td className="w-60"></td>
                                        <td className="text-end w-full  pr-2 whitespace-nowrap">
                                            {montantTVA.toFixed(2)} €
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="px-2 font-bold text-base w-full" > Total TTC
                                        </td>
                                        <td className="w-60"></td>
                                        <td className="text-end w-full pr-2 font-bold whitespace-nowrap">
                                            {totalTTC.toFixed(2)} €
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            )}

            <div className="flex justify-between p-2">
                <StepIndicator currentStep={currentStep} setCurrentStep={setCurrentStep} categoryFormalitie={categoryFormalitie} />

                <button
                    className="p-2 rounded-lg px-8 bg-[#06094F] text-white hover:bg-[#3F83CA] hover:border-[#3F83CA] transition-colors duration-150"
                    onClick={nextStep}
                >
                    Continuer
                </button>
            </div>
        </div>
    );
};


export default ConfirmationOrder;